body {
  margin: 0;
  padding: 0;
}
.use-dark-bg {
  background-color: rgb(26, 26, 26) !important;
}
.use-abril-font {
  font-family: 'Abril Fatface', cursive;
}

.use-oswald-font {
  font-family: 'Oswald', sans-serif;
}

.use-vidaloka-font {
  font-family: 'Vidaloka', serif;
}

.use-alegreya-font {
  font-family: 'Alegreya Sans SC', sans-serif;
}
.main-logo {
  height: 90px;
}
.main-logo img {
  height: 100%;
  width: auto;
}

.navbar-top-menu {
  font-family: 'Oswald', sans-serif;
  font-size: 1.3rem;
}
.navbar-top-menu:hover {
  color: red !important;
}
.active-nav-link {
  border-bottom: 1px solid red;
}
.active-nav-link span {
  color: red !important;
}

.showPass {
  position: relative;
  left: 10px;
}

.dashboard-container,
.blog-admin-container,
.client-admin-container,
.v-consulta-wrapper,
.pay-container,
.appointments-admin-container {
  margin-top: 20px;
}
.close-icon-edu a i {
  position: absolute;
  top: 0;
  right: 0;
}

.lang-switcher-container {
  position: absolute;
  top: 0px;
  right: 5%;
  z-index: 998;
}
.parallax-container .parallax img {
  opacity: 1 !important;
}
.shadow {
  box-shadow: inset 10px 10px 10px #fff;
}
.right-panel {
  border-left: 1px solid red;
  padding-left: 10px;
  position: sticky;
  top: 10%;
  bottom: 10%;
}

@media only screen and (max-width: 600px) {
  .right-panel {
    position: inherit;
  }
}

.tag-cloud > div {
  transition: 1.4s;
}
.bottom-h-reviews {
  position: relative;
  bottom: 0px;
  left: 0px;
  right: 0px;
  top: 0px;
}
.bottom-h-info {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  top: 80vh;
}
.reviews-card {
  background-color: rgba(255, 255, 255, 0.5) !important;
  border: red solid 1px;
}
.p-info-l {
  position: absolute;
  left: 0px;
  top: -10px;
  background-color: white;
  width: 50%;
  height: 100vh;
}
.p-info-r {
  position: absolute;
  right: 0px;
  top: -10px;
  background-color: white;
  width: 50%;
  height: 100vh;
}
.p-info-text-r {
  margin: 15px 10px;
  padding: 10px 15px;
  border-bottom: red solid 1px;
  border-right: red solid 1px;
  /* border: red solid 1px; */
  box-shadow: 5px 5px 5px 5px rgb(228, 226, 226);
  /* background-color: rgb(248, 248, 248); */
}
.p-info-text-l {
  margin: 15px 10px;
  padding: 10px 15px;
  border-bottom: red solid 1px;
  border-left: red solid 1px;
  /* border: red solid 1px; */
  box-shadow: 0px 5px 5px 5px rgb(228, 226, 226);
  /* background-color: rgb(248, 248, 248); */
}
.parallax-container .parallax {
  z-index: 0 !important;
}
.header-tabs {
  position: absolute;
  bottom: 0px;
  left: 15%;
  right: 15%;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 80%
  );
  height: 140px;
  z-index: 2;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.extra-list ul {
  display: flex;
  flex-direction: row;
}
.extra-list {
  margin-left: 20px;
  margin-right: 20px;
}
@media only screen and (max-width: 600px) {
  .header-tabs {
    left: 0px;
    right: 0px;
  }
  .extra-list ul {
    display: flex;
    flex-direction: column;
  }
}
.hover-phone:hover {
  border: 1px solid red !important;
  background-color: transparent !important;
  color: red !important;
}
.hover-acc:hover {
  background-color: transparent !important;
  color: red !important;
}
.consent {
  position: fixed;
  bottom: 0px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.89);
}
.consent-msg span {
  font-size: 1rem;
}
.consent-btn button {
  margin: 5px 5px;
}
.social-footer-icons-wrapper {
  width: 20%;
}
.social-footer-icons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.social-footer-icons svg,
img {
  margin-right: 10px;
}
.contact-links {
  color: white;
}
.contact-links:hover {
  color: red;
  border-bottom: 1px solid red;
}
.google-badge {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 270px;
  border-radius: 20px;
  border: 1px solid white;
  box-shadow: 2px 2px lightgrey;
  background-color: rgb(51, 51, 51);
}
.google-badge-r3 {
  font-family: Oswald;
  font-size: 1.2rem;
}
.bottom-menu {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  overflow: hidden;
  align-items: center;
  padding-bottom: 10px;
  font-family: Oswald;
  font-size: 1rem;
}
.bottom-menu a {
  text-decoration: underline solid white;
}
@media only screen and (max-width: 600px) {
  .bottom-menu {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    margin-left: 20px;
  }
}
.post-share-link {
  border: 1px solid lightgray;
  padding: 5px 5px;
}
.post-share-reveal {
  position: absolute;
  top: 40%;
  left: 5%;
  right: 5%;
}
.custom-card-reveal {
  background-color: rgba(0, 0, 0, 0.596) !important;
}
.full-post-header {
  position: absolute;
  left: 50%;
  top: 80%;
  width: 80%;
  transform: translate(-50%, -50%);
}
.full-post-description {
  position: absolute;
  bottom: -15%;
  left: 5%;
  right: 5%;
}
.header-blog-container {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.header-blog-text {
  font-size: 2rem;
  font-family: 'Cinzel';
}
@media only screen and (min-width: 600px) {
  .full-post-header {
    position: absolute;
    left: 50%;
    top: 40%;
    width: 90%;
    transform: translate(-50%, -50%);
  }
  .full-post-description {
    position: absolute;
    bottom: 25%;
    left: 5%;
    right: 5%;
  }
  .full-post-description h5 {
    word-wrap: break-word;
  }
  .full-post-header h2 {
    word-wrap: break-word;
  }
  .header-blog-text {
    font-size: 3rem;
    font-family: 'Cinzel';
  }
}
@media only screen and (max-width: 992px) {
  .full-post-header {
    position: absolute;
    left: 50%;
    top: 30%;
    width: 90%;
    transform: translate(-50%, -50%);
  }
  .full-post-description {
    position: absolute;
    top: 35%;
    left: 5%;
    right: 5%;
  }
  .full-post-description h5 {
    font-size: 1.4rem;
    word-wrap: break-word;
  }
  .full-post-header h2 {
    font-size: 2.2rem;
    word-wrap: break-word;
  }
  .header-pic-container img {
    object-fit: cover;
    width: 100%;
    height: 40vh !important;
  }
  .header-blog-text {
    font-size: 2.7rem;
    font-family: 'Cinzel';
  }
}
.custom-landing-container {
  height: 100vh !important;
}
.p-info-l-text-title {
  font-family: 'Cinzel';
  text-align: center;
  color: red;
  text-shadow: 2px 2px lightgrey;
}
.p-info-l-text-title-e {
  font-family: 'Cinzel';
  text-align: center;
  color: red;
  text-shadow: 2px 2px lightgrey;
}
.p-info-l-text-desc {
  font-size: 0.8rem;
}
.p-info-check {
  font-family: 'Cinzel';
}
@media only screen and (max-width: 600px) {
  .p-info-l-text-title {
    font-family: 'Cinzel';
    text-align: center;
    color: red;
    text-shadow: 2px 2px lightgrey;
    font-size: 2rem;
  }
  .p-info-l-text-title-e {
    font-family: 'Cinzel';
    text-align: center;
    color: red;
    text-shadow: 2px 2px lightgrey;
    font-size: 1.5rem;
  }
  .bottom-h-reviews {
    position: relative;
    bottom: 0px;
    left: 0px;
    right: 0px;
    top: 0px;
  }
  .custom-landing-container {
    height: 150vh !important;
  }
  .header-blog-text {
    font-size: 2.5rem;
    font-family: 'Cinzel';
  }
  .full-post-header {
    position: absolute;
    top: 25%;
    width: 90%;
  }
  .full-post-header h2 {
    font-size: 1.4rem;
    word-wrap: break-word !important;
  }
  .full-post-description {
    position: absolute;
    top: 30%;
    left: 5%;
    right: 5%;
  }
  .full-post-description h5 {
    font-size: 1rem;
    word-wrap: break-word;
  }
  .header-pic-container img {
    object-fit: cover;
    width: 100%;
    height: 35vh !important;
  }
}
.header-pic-container img {
  object-fit: cover;
  width: 100%;
  height: 80vh;
}
.full-post-description h5 {
  word-wrap: break-word;
}
.full-post-header h2 {
  word-wrap: break-word;
}
.post-lang {
  position: absolute;
  bottom: 0px;
  right: 50px;
  background-color: red;
}
.main-info-banner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* border-radius: 10px; */
  font-size: 1.1rem;
}
.sidenav-overlay {
  z-index: 996 !important;
}
.tags-container-full {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}
.full-post-share {
  position: relative;
  /* margin: 10px; */
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.post-info-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.video-call-wrapper {
  /* background-color: grey; */
  height: 90vh;
  width: 100%;
  position: relative;
}
video {
  transform: scaleX(-1);
}
.video-call-wrapper video {
  height: 90vh;
  width: 100%;
  object-fit: cover;
}
.self-cam-container {
  position: absolute;
  top: 20px;
  right: 5px;
  height: 124px;
  width: 124px;
  /* background-color: red; */
  border-radius: 5px;
}
.self-cam-container video {
  height: 124px;
  width: 124px;
  object-fit: cover;
  border-radius: 5px;
  z-index: 2;
}
.video-call-answer-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 10px;
  z-index: 2;
}
.video-call-answer-container span {
  padding: 10px 0;
  color: white;
}
.controls-wrapper {
  position: absolute;
  bottom: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
}
.controls-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 10px;
  width: 300px;
}
.controls-container button {
  margin-left: 10px;
}
.controls-container button:hover {
  transform: scale(1.12);
  transition: all 0.2s ease;
  color: #990d35 !important;
}
.btn-end-call {
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.btn-call-common {
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.video-call-stats-wrapper {
  position: absolute;
  left: 0;
  top: 100px;
  width: 250px;
  height: 100px;
  background-color: rgba(0, 0, 0, 0.5);
}
.video-status-info {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.video-status-info div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.video-status-info div span:nth-child(2) {
  color: white;
  font-size: 1.2rem;
  margin-bottom: 20px;
  text-align: center;
}
.video-status-info div span:nth-child(3) {
  color: white;
  font-size: 0.8rem;
  text-align: center;
  padding: 20px;
}
.video-status-info div div {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.side-card {
  height: 822px;
}
.hour-scheduler-nav {
  position: absolute;
  height: 65px;
  width: 100%;
  z-index: 2;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.hour-scheduler-title {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.hour-scheduler-title button:nth-child(2) {
  font-weight: bold !important;
}
.hour-scheduler-title span {
  font-size: 1.2rem;
  font-weight: bold;
}
.card-overflow {
  padding-top: 65px;
  height: 800px;
  overflow-y: scroll;
}
.mini-card {
  height: 150px;
  background-color: #d81159 !important;
  margin: 5px !important;
  padding: 5px;
  display: flex;
  flex-direction: column;
  position: relative;
}
.mini-card:hover {
  box-shadow: 1px 1px 5px 1px grey;
}
.mini-card-free {
  height: 150px;
  background-color: rgb(241, 241, 241) !important;
  margin: 5px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
}
.mini-card-content-free {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mini-card-free-busy {
  position: absolute;
  bottom: 10px;
  left: 0;
}
.mini-card-title {
  display: flex;
  justify-content: space-between;
}
.mini-card-title span {
  font-weight: bold;
}
.mini-card-content-title {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f01867 !important;
}
.mini-card-content-title span {
  text-transform: uppercase;
  color: white;
}
.mini-card-content-text span {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 0.9rem;
  color: white;
}
.mini-card-action {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.video-call-user-info {
  height: 500px;
  display: flex;
  flex-direction: column;
}
.video-call-user-info div:nth-child(2) {
  padding-top: 80px;
  margin-bottom: 40px;
  overflow-y: scroll;
}
.video-call-user-info div:nth-child(2) p:nth-child(1) {
  font-weight: bold;
  text-transform: uppercase;
}
.video-call-user-info div:nth-child(2) p:nth-child(2) {
  color: lightgray;
  font-weight: bold;
}
.video-call-user-info div:nth-child(2) p:nth-child(3) {
  text-align: justify;
}
.video-call-user-info div:nth-child(1) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 40px;
  background-color: white;
  box-shadow: 2px 2px 10px 0px lightgray;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.video-call-user-info div:nth-child(1):hover {
  transition: all 0.5s ease;
  height: 100px;
}
.video-call-user-info div:nth-child(1) > p {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 600px) {
  .video-call-user-info div:nth-child(1) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    background-color: white;
    box-shadow: 2px 2px 10px 0px lightgray;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }
}
.video-call-actions {
  margin-bottom: 40px;
  height: 500px;
  border-left: 1px solid lightgray;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.video-call-actions div:nth-child(1) {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.video-call-actions div:nth-child(2) {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.no-date-selected {
  height: 500px;
  width: 100%;
}
.no-date-selected img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.no-date-selected div {
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.no-date-selected div span {
  padding: 10px;
  font-weight: bold;
  background-color: rgba(255, 255, 255, 0.9);
}
.header-info {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.card-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 150px;
  height: 75px;
  padding: 2px;
  background-color: #cc0e44 !important;
}
.card-info-logo {
  display: flex;
  justify-self: center;
  align-items: center;
}
.card-info-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
}
.card-info-text span {
  font-size: 1.4rem;
  color: white;
}
.auth-modal-login {
  width: 400px !important;
}
.login-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 20px;
}
.auth-title {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.auth-title h5 {
  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
}
.auth-footer {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.code-input-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.code-input-container input {
  margin: 5px !important;
  background-color: rgb(212, 212, 212) !important;
  border-radius: 10px !important;
  width: 40px !important;
  text-align: center;
  font-size: 2rem !important;
  color: white;
}
.code-input-container input::placeholder {
  text-align: center;
  color: rgb(182, 182, 182) !important;
}
.progress {
  background-color: lightgray !important;
}
.payment-progress-container {
  margin-top: 40px;
  margin-bottom: 100px;
}
.step-one {
  position: absolute;
  top: 139px;
  left: 10%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.step-two {
  position: absolute;
  top: 139px;
  left: 35%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.step-three {
  position: absolute;
  top: 139px;
  left: 55%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.step-four {
  position: absolute;
  top: 139px;
  left: 80%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 600px) {
  .payment-progress-container {
    margin-left: 0;
    margin-right: 0;
  }
  .step-one {
    top: 145px;
    left: 5%;
  }
  .step-one i {
    font-size: 2.5rem !important;
  }
  .step-two {
    top: 145px;
    left: 32%;
  }
  .step-two i {
    font-size: 2.5rem !important;
  }
  .step-three {
    top: 145px;
    left: 55%;
  }
  .step-three i {
    font-size: 2.5rem !important;
  }
  .step-four {
    top: 145px;
    left: 75%;
  }
  .step-four i {
    font-size: 2.5rem !important;
  }
}
.step-footer {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pay-logos {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.pay-logos img {
  width: 100px;
  object-fit: contain;
}
.pay-info {
  display: flex;
  flex-direction: column;
}
.pay-info > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 50px;
}
.pay-info div:nth-child(1) {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pay-info div:nth-child(4) p {
  font-size: 1.3rem;
  font-weight: bold;
}
.pay-info div:nth-child(1) p {
  font-size: 2rem;
}
.pay-info div:nth-child(5) {
  font-size: 0.8rem;
  color: rgb(158, 158, 158);
  text-align: justify;
  margin-top: 10px;
  margin-bottom: 30px;
}
.check-ok-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.check-ok-circle {
  border: 2px solid green;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  animation: checkCircle 2s linear infinite;
  display: flex;
  justify-content: center;
  align-items: center;
}
@keyframes checkCircle {
  0% {
    border-color: blue;
  }
  50% {
    border-color: yellow;
  }
  100% {
    border-color: red;
  }
}
.field-required {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.field-required span {
  margin-top: 10px;
  color: lightgray !important;
  font-size: 0.9rem !important;
  text-decoration: none !important;
  text-shadow: none !important;
}
.required {
  display: flex;
  justify-content: flex-end;
}
.required span {
  color: lightgrey;
}
.buy-wrapper {
  margin-top: 20px;
  margin-left: 10px !important;
}
.buy-wrapper p:nth-child(1) {
  margin-bottom: 10px !important;
  font-size: 1.1rem;
  font-family: 'Oswald', sans-serif;
  letter-spacing: 0.5px;
}
.buy-btn {
  margin-top: 20px;
}
.admin-cal-container {
  display: flex;
  flex-direction: column;
  height: 640px;
  margin: 20px 10px;
  overflow: hidden;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}
.admin-cal-header {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 20px 0px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}
.admin-cal-header > span {
  color: grey;
  font-weight: bold;
  text-transform: uppercase;
}
.admin-cal-header span:nth-child(1) {
  text-align: center;
  width: 100px;
}
.admin-cal-days-container {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: scroll;
}
.admin-cal-day-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  border-bottom: 1px solid lightgray;
  position: relative;
}
.admin-cal-day-reset-dayoff {
  position: absolute;
  left: 0;
  top: -10px;
}
.admin-cal-day-reset-dayoff button {
  padding: 0 !important;
  margin: 0 !important;
  background-color: transparent !important;
  font-size: 0.7rem !important;
}
.admin-cal-date {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
}
.admin-cal-date span:nth-child(1) {
  color: gray;
}
.admin-cal-date span:nth-child(2) {
  color: gray;
}
.admin-cal-today {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
}
.admin-cal-today span:nth-child(1) {
  color: #3f84e5;
  font-weight: bold;
  text-transform: uppercase;
  text-shadow: 4px 4px 4px lightgray;
}
.admin-cal-today span:nth-child(2) {
  color: #3f84e5;
  font-weight: bold;
  text-shadow: 4px 4px 4px lightgray;
}
.admin-cal-hour {
  display: flex;
  justify-content: center;
  align-items: center;
}
.day-off {
  display: flex;
  align-items: center;
  width: 35px;
  height: 42px;
}
@media only screen and (max-width: 600px) {
  .admin-cal-header {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 20px 0px;
    width: 800px;
  }
  .admin-cal-days-container {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    width: 800px;
  }
}
.files-container {
  overflow-y: scroll;
}
.files-container ul {
  display: flex;
  flex-direction: column;
}
.file-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 0px 1px 0px lightgray;
}
.file-wrapper:hover {
  box-shadow: 1px 1px 10px 1px lightgray;
}
.file-wrapper div:nth-child(1) {
  display: flex;
  flex-direction: row;
}
.file-wrapper div div p:nth-child(1) {
  font-weight: bold;
}
.file-wrapper div div p:nth-child(2) {
  color: grey;
}
.file-wrapper div:nth-child(2) > button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.files-container-no-data {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.files-container-no-data img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.files-container-no-data span {
  position: absolute;
  bottom: 10px;
  font-weight: bold;
}
.acc-card {
  height: 400px;
}
@media only screen and (max-width: 600px) {
  .acc-card {
    height: auto;
  }
}
.acc-l-wrapper ul > li button {
  margin: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border: none;
  background-color: white;
  cursor: pointer;
}
.acc-l-wrapper ul > li button:hover {
  transition: all 0.3s ease;
  transform: scale(1.1);
  color: rgb(0, 157, 255);
}
.acc-l-wrapper-btn-active {
  margin: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border: none;
  background-color: white;
  cursor: pointer;
  color: rgb(0, 157, 255);
}
.acc-l-wrapper-btn-active span {
  font-weight: bold;
}
.acc-l-wrapper ul > li i {
  margin: 2px;
}
.acc-r-wrapper {
  padding: 10px;
}
.top-search-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 40px;
}
.top-search-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 70%;
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 10px;
}
.top-search-bar input {
  height: 20px !important;
  padding: 10px !important;
  margin: 0px !important;
  border-bottom: none !important;
}
.top-search-bar input:focus {
  border-bottom: none !important;
  box-shadow: none !important;
}
.top-search-bar input::placeholder {
  padding: 0px !important;
}
.top-search-bar button {
  border: none;
  cursor: pointer;
}
.clients-table-wrapper {
  max-height: 600px;
  padding-bottom: 10px;
  padding-top: 40px;
}
.clients-table-header {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 40px;
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  background-color: white;
}
.clients-table-header div:nth-child(1) span {
  font-weight: bold;
}
.clients-table-header div:nth-child(2) span {
  font-weight: bold;
}
.clients-table {
  overflow-y: scroll;
}
.meetings-list-wrapper {
  max-height: 200px;
  overflow-y: scroll;
}
.modal-title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.modal-title-container span:nth-child(1) {
  font-weight: bold;
}
.modal-title-container span:nth-child(2) {
  color: lightgray;
}
.case-file-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid lightgray;
  padding: 10px;
  border-radius: 10px;
  margin: 10px;
}
.case-file-wrapper:hover {
  box-shadow: 4px 4px 7px 1px lightgray;
}
.case-file-left div {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
}
.case-file-left div span:nth-child(1) {
  font-weight: bold;
}
.case-file-left div span:nth-child(n + 2) {
  color: grey;
}
.case-file-right {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.case-file-right > button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.case-file-right > button:hover,
.case-file-right > button:focus {
  background-color: transparent;
  font-weight: bold;
}
.custom-collapsible {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.custom-collapsible div:nth-child(1) {
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom-collapsible div:nth-child(1) i {
  font-size: 2.4rem;
}
.custom-collapsible div:nth-child(2) {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-end;
  max-width: 200px;
}
.custom-collapsible div:nth-child(2) > span {
  color: grey;
}
.no-case-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px;
}
.no-case-wrapper span {
  margin-bottom: 10px;
}
.blog-container {
  display: flex;
  flex-direction: column;
  margin: 40px;
}
.blog-post-container {
  display: flex;
  flex-direction: column;
}
.blog-post-container div:nth-child(1) p:nth-child(1) {
  text-transform: uppercase;
  font-size: 1.2rem;
  text-align: center;
  color: red;
}
.blog-post-container div:nth-child(1) p:nth-child(2) {
  font-size: 2.2rem;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.blog-post-container div:nth-child(1) div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.blog-post-container div:nth-child(1) div span:nth-child(1) {
  border-bottom: 1px solid lightgray;
  margin-right: 10px;
  width: 10%;
  background-color: red;
}
.blog-post-container div:nth-child(1) div p:nth-child(2) {
  font-size: 0.9rem;
  text-align: center;
}
.blog-post-container div:nth-child(1) div span:nth-child(3) {
  border-bottom: 1px solid lightgray;
  margin-left: 10px;
  width: 10%;
}
.blog-post-container div:nth-child(2) img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.blog-post-container div:nth-child(3) {
  padding: 20px 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.blog-post-container div:nth-child(3) p:nth-child(1) {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 1.1rem;
  text-align: justify;
}
.blog-post-container div:nth-child(3) p:nth-child(3) {
  border-bottom: 1px solid black;
  width: 100px;
}
.blog-post-container div:nth-child(3) p:nth-child(3):hover {
  border-bottom: 1px solid red;
  width: 200px;
}
.blog-post-container div:nth-child(3) a {
  text-transform: uppercase;
  color: black;
}
.blog-post-container div:nth-child(3) a:hover {
  text-transform: uppercase;
  color: red;
  font-weight: bold;
}
.blog-post-container div:nth-child(3) div {
  border-bottom: 1px solid lightgray;
  margin-top: 10px;
  padding-bottom: 10px;
}

.blog-post-container div:nth-child(4) {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}
.blog-post-container div:nth-child(4) span:nth-child(1) {
  border-bottom: 1px solid lightgray;
  margin-right: 10px;
  width: 35%;
}
.blog-post-container div:nth-child(4) span:nth-child(6) {
  border-bottom: 1px solid lightgray;
  margin-left: 10px;
  width: 35%;
}
.blog-post-container div:nth-child(4) > a {
  margin: 0px 10px;
  font-size: 2rem;
  color: black;
}
.blog-post-container div:nth-child(4) a:nth-child(2):hover {
  margin: 0px 10px;
  font-size: 2rem;
  color: #4267b2;
  transition: all 0.5s ease;
}
.blog-post-container div:nth-child(4) a:nth-child(3):hover {
  margin: 0px 10px;
  font-size: 2rem;
  color: #1da1f2;
  transition: all 0.5s ease;
}
.blog-post-container div:nth-child(4) a:nth-child(4):hover {
  margin: 0px 10px;
  font-size: 2rem;
  color: #2867b2;
  transition: all 0.5s ease;
}
.blog-post-container div:nth-child(4) a:nth-child(5):hover {
  margin: 0px 10px;
  font-size: 2rem;
  color: orange;
  transition: all 0.5s ease;
}
.blog-full-post {
  display: flex;
  flex-direction: column;
  margin: 40px;
}
.blog-full-footer {
  display: flex;
  flex-direction: column;
  margin: 20px 0px;
}
.blog-full-footer-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.blog-full-footer-header span:nth-child(1) {
  border-bottom: 1px solid lightgray;
  width: 35%;
  margin-right: 20px;
}
.blog-full-footer-header span:nth-child(3) {
  border-bottom: 1px solid lightgray;
  width: 35%;
  margin-left: 20px;
}
.blog-full-footer-header span:nth-child(2) {
  font-size: 1.2rem;
}
.blog-full-footer-content {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin: 20px 0px;
}
.blog-full-footer-content > div {
  height: 350px;
  width: 350px;
  box-shadow: 4px 4px 10px 1px lightgrey;
}
.blog-full-footer-content > div:hover {
  height: 350px;
  width: 350px;
  box-shadow: 4px 4px 10px 1px rgb(151, 150, 150);
}
.blog-full-footer-content > div > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.blog-full-footer-content > div > a {
  position: relative;
  top: -355px;
  height: 350px;
  width: 350px;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 20px;
}
.blog-full-footer-content > div > a p:nth-child(1) {
  color: white;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.blog-full-footer-content > div > a p:nth-child(2) {
  color: white;
  font-size: 0.8rem;
}
@media only screen and (max-width: 600px) {
  .blog-full-footer-content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin: 20px 0px;
  }
  .blog-full-footer-header span:nth-child(2) {
    font-size: 0.9rem;
  }
  .blog-full-footer-content > div {
    height: 350px;
    width: 350px;
    box-shadow: 4px 4px 10px 1px lightgrey;
    margin-bottom: 20px;
  }
}
.blog-full-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 20px;
}
.blog-full-title div {
  display: flex;
  flex-direction: column;
  padding: 0px 40px;
}
.blog-full-title div p:nth-child(1) {
  color: red;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}
.blog-full-title div p:nth-child(2) {
  font-size: 2.2rem;
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-left: 80px;
  margin-right: 80px;
}
.blog-full-title div div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100vw;
}
.blog-full-title div div span:nth-child(1) {
  border-bottom: 1px solid lightgrey;
  width: 45%;
  margin-right: 20px;
}
.blog-full-title div div span:nth-child(2) {
  width: 100px;
  text-align: center;
}
.blog-full-title div div span:nth-child(3) {
  border-bottom: 1px solid lightgrey;
  width: 45%;
  margin-left: 20px;
}
.blog-full-content {
  display: flex;
  flex-direction: column;
}
.blog-full-content img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.blog-full-content div:nth-child(2) {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0px 40px;
  border-bottom: 1px solid lightgrey;
  border-top: 1px solid lightgrey;
}
.blog-full-content div:nth-child(2) div:nth-child(1) a {
  color: black;
  border: 1px solid black;
  padding: 10px;
}
.blog-full-content div:nth-child(2) div:nth-child(1) a:hover {
  color: red;
  border: 1px solid red;
  padding: 10px;
}
.blog-full-content div:nth-child(2) div:nth-child(2) {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;
}
.blog-full-content div:nth-child(2) div:nth-child(2) > a {
  font-size: 2rem;
  margin: 0px 4px;
  color: black;
}
.blog-full-content div:nth-child(2) div:nth-child(2) a:nth-child(1):hover {
  font-size: 2rem;
  margin: 0px 4px;
  color: #4267b2;
  transition: all 0.3s ease;
}
.blog-full-content div:nth-child(2) div:nth-child(2) a:nth-child(2):hover {
  font-size: 2rem;
  margin: 0px 4px;
  color: #1da1f2;
  transition: all 0.3s ease;
}
.blog-full-content div:nth-child(2) div:nth-child(2) a:nth-child(3):hover {
  font-size: 2rem;
  margin: 0px 4px;
  color: #2867b2;
  transition: all 0.3s ease;
}
.blog-full-content div:nth-child(2) div:nth-child(2) a:nth-child(4):hover {
  font-size: 2rem;
  margin: 0px 4px;
  color: orange;
  transition: all 0.3s ease;
}
.blog-full-content div:nth-child(3) {
  margin: 20px 20px;
}
.blog-full-content div:nth-child(3) span {
  text-align: justify;
}
.blog-full-content div:nth-child(4) {
  margin: 0px 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.blog-full-content div:nth-child(4) > a {
  margin: 0px 4px;
  padding: 4px;
  background-color: white;
  color: lightgray;
  border: 1px solid lightgray;
}
.blog-full-content div:nth-child(4) > a:hover {
  border: 1px solid lightgray;
  margin: 0px 4px;
  padding: 4px;
  background-color: lightgrey;
  color: white;
}
.areas-header {
  width: 100%;
  height: 250px;
}
.areas-header img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 0% 100%;
}
.areas-tabs-container {
  display: flex;
  flex-direction: column;
  margin: 0px 40px;
}
.areas-tabs-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.areas-tabs-title h5 {
  color: red;
  font-weight: bold;
  text-transform: uppercase;
}
.areas-tabs-title span {
  color: grey;
}
.areas-tabs-wrapper {
  margin: 20px 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.areas-tabs-wrapper > button {
  background-color: rgb(26, 26, 26);
  height: 150px;
  width: 200px;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #990d35;
  font-size: 2.4rem;
  cursor: pointer;
}
.areas-tabs-wrapper > button:active,
.areas-tabs-wrapper > button:focus {
  transform: scale(1.2);
  transition: all 1s ease;
  background-color: #990d35;
  color: white;
}
.areas-tabs-wrapper > button span {
  color: white;
  font-size: 1.2rem;
  margin: 10px 0px;
}
.areas-tabs-wrapper > button:hover {
  transform: scale(1.2);
  transition: all 1s ease;
  background-color: #990d35;
  color: white;
}
.areas-tabs-title span {
  text-align: center;
}
.app-hero-wrapper {
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}
.app-hero-wrapper div:nth-child(1) {
  height: 100%;
  width: 60%;
}
.app-hero-wrapper div:nth-child(1) img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.app-hero-wrapper div:nth-child(2) {
  height: 100%;
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.hero-price-card-wrapper {
  height: 500px !important;
  width: 400px !important;
  background-color: rgb(26, 26, 26);
  box-shadow: 4px 4px 20px 1px #990d35;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.hero-price-card-wrapper div:nth-child(1) {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.hero-price-card-wrapper div:nth-child(1) img {
  width: 80%;
  height: 80%;
  object-fit: contain;
}
.hero-price-card-wrapper div:nth-child(1) div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.hero-price-card-wrapper div:nth-child(2) {
  height: 100%;
  width: 100%;
}
.hero-price-card-wrapper div:nth-child(2) span {
  color: white;
  font-size: 1.4rem;
  text-align: center;
}
.hero-price-card-wrapper div:nth-child(3) {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.hero-price-card-wrapper div:nth-child(3) div:nth-child(1) {
  width: 100%;
  background-color: #990d35;
  margin-bottom: 10px;
}
.hero-price-card-wrapper div:nth-child(3) div:nth-child(1) span {
  font-weight: bold;
  font-size: 1.4rem;
  font-family: 'Oswald', sans-serif;
}
.hero-price-card-wrapper div:nth-child(3) div:nth-child(2) {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.hero-price-card-wrapper div:nth-child(3) div:nth-child(2) span:nth-child(1) {
  color: white;
  font-size: 1.5rem;
}
.hero-price-card-wrapper div:nth-child(3) div:nth-child(2) span:nth-child(2) {
  color: #990d35;
  font-size: 1.4rem;
}
.hero-price-card-wrapper div:nth-child(4) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.hero-price-card-wrapper div:nth-child(4) span:nth-child(1) {
  color: white;
  font-size: 4.2rem;
  text-align: center;
}
.hero-price-card-wrapper div:nth-child(4) span:nth-child(2) {
  color: grey;
  font-size: 0.9rem;
  text-align: center;
  text-decoration: overline;
}
.hero-price-card-wrapper div:nth-child(5) {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
}
.hero-price-card-wrapper div:nth-child(5) a {
  border: 1px solid #990d35;
  padding: 10px;
  border-radius: 2px;
  background-color: transparent;
  color: #990d35;
  text-transform: uppercase;
  margin-top: 10px;
}
.hero-price-card-wrapper div:nth-child(5) a:hover {
  padding: 10px;
  border-radius: 2px;
  background-color: #990d35;
  color: white;
  text-transform: uppercase;
  transition: all 0.5s ease;
}
.hero-price-card-wrapper div:nth-child(5) p:nth-child(2) {
  color: lightgray;
  font-size: 0.7rem;
  text-align: justify;
}
@media only screen and (max-width: 600px) {
  .app-hero-wrapper {
    height: 80vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
.despacho-container {
  height: 200vh;
}
.scroll-img-scaled {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 100px;
}
.scroll-img-scaled img {
  height: 50%;
  width: 100%;
  object-fit: cover;
  transition: all 1s ease-in-out;
  transform: translateY(-250px);
}
.scroll-img-full {
  height: 90vh;
}
.scroll-img-full img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: all 1s ease-in-out;
}
.despacho-title-container {
  display: flex;
  flex-direction: row;
}
.despacho-title-left {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  line-height: 2rem;
}
.despacho-title-left span:nth-child(1) {
  font-size: 2.4rem;
  color: rgb(26, 26, 26);
  text-shadow: 4px 4px 4px lightgray;
}
.despacho-title-left span:nth-child(2) {
  color: red;
  font-size: 2.3rem;
  letter-spacing: 0.5rem;
  text-shadow: 4px 4px 4px lightgray;
}
.despacho-title-right {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.despacho-title-right span {
  font-family: 'Vidaloka';
  color: rgb(26, 26, 26);
}

.despacho-details div:nth-child(1),
.despacho-details div:nth-child(4) {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.despacho-details div:nth-child(1) > span,
.despacho-details div:nth-child(4) > span {
  border-bottom: 1px solid red;
  width: 20%;
}
.despacho-details div:nth-child(1) h5,
.despacho-details div:nth-child(4) h5 {
  text-align: center;
  font-family: 'Cinzel';
  font-weight: bold;
  margin: 0px 20px;
  text-shadow: 4px 4px 4px lightgray;
}
.despacho-details p:nth-child(3) {
  color: lightgray;
  font-family: 'Cinzel';
  font-size: 2rem;
  padding: 0px 40px;
}
.despacho-details div {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.despacho-details div img {
  margin-top: 250px;
  width: 50%;
  height: 50%;
  object-fit: cover;
  box-shadow: 4px 4px 10px 1px lightgray;
}
.despacho-details div div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 50%;
}
.despacho-details div div p:nth-child(1) {
  margin: 0;
}
.despacho-details p {
  text-align: justify;
}
.despacho-details div:nth-child(7) {
  position: relative;
  top: -250px;
  display: flex;
  flex-direction: column;
}
.despacho-details div:nth-child(7) p:nth-child(3) {
  font-size: 1.2rem;
  color: grey;
  font-family: Arial, Helvetica, sans-serif;
}
.despacho-details div:nth-child(7) a {
  margin: 20px;
  width: 150px;
  align-self: center;
}
.move-text-up-100 {
  animation: moveUpAndShow 1.5s linear 1;
  animation-fill-mode: forwards;
}
@keyframes moveUpAndShow {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateY(250px);
  }
}
@media only screen and (max-width: 600px) {
  .despacho-title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .despacho-title-right {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 20px;
    text-align: center;
  }
  .despacho-details div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100% !important;
  }
  .despacho-details div img {
    margin-bottom: -200px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    box-shadow: 4px 4px 10px 1px lightgray;
  }
  .despacho-container {
    height: 450vh;
  }
  .despacho-details {
    margin-top: 180vh;
    width: 100vw;
    padding: 0px 10px;
  }
  .despacho-details div:nth-child(7) {
    position: relative;
    top: 10px;
    display: flex;
    flex-direction: column;
  }
  .despacho-details p:nth-child(3) {
    color: lightgray;
    font-family: 'Cinzel';
    font-size: 1rem;
    padding: 0px 10px;
  }
}
.appointment-container p {
  text-align: justify;
}
.appointment-container h5 {
  font-family: 'Oswald', sans-serif;
}
.steps-container {
  margin-top: 50px;
  display: flex;
  justify-content: space-evenly;
}
.steps-container > div {
  position: relative;
  background-color: #1a1a1a;
  margin: 2px;
  width: 20%;
  height: 200px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  box-shadow: 0px 20px 20px -10px #990d35;
}
.steps-container > div span:nth-child(1) {
  position: absolute;
  top: -80px;
  color: white;
  text-shadow: 2px 2px 10px #990d35;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 140px;
  height: 150px;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.steps-container > div span:nth-child(2) {
  color: lightgray;
  text-shadow: 1px 1px 5px lightgray;
  text-align: center;
  text-transform: uppercase;
}
@media only screen and (max-width: 600px) {
  .steps-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .steps-container > div {
    background-color: #1a1a1a;
    margin-top: 80px;
    width: 100%;
    height: 200px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    box-shadow: 4px 4px 20px 1px #990d35;
  }
}
.login-btn-custom {
  margin-top: 30px;
  height: inherit !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
}
@media only screen and (min-width: 992px) {
  .register-container {
    display: flex;
    flex-direction: column;
    height: 80vh;
  }
  .register-container img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .register-card-wrapper {
    position: absolute;
    left: 0;
    top: 40%;
    height: 600px;
    width: 400px;
    background-color: white;
    animation: showRegister 1.5s linear 1;
    animation-fill-mode: forwards;
  }
}
@keyframes showRegister {
  0% {
    opacity: 0;
    transform: translateX(-50%);
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
    transform: translate(40%, -30%);
  }
}
.input-fields {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.custom-input-field {
  height: 40px;
  width: 70%;
}
.custom-input-field input {
  line-height: 10px !important;
  font-size: 0.9rem !important;
}
.custom-input-field::placeholder {
  color: lightgrey;
}
.eye-btn {
  margin: 10px;
}
@media only screen and (max-width: 600px) {
  .register-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .register-card-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
    background-color: white;
  }
}
.video-consulta-promo-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999;
}
.video-consulta-promo {
  height: 500px;
  width: 600px;
  background-color: white;
  box-shadow: 4px 4px 10px 1px grey;
  display: flex;
  flex-direction: column;
}
.buy-title h5 {
  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
}
.step-h6 h6 {
  font-family: 'Oswald', sans-serif;
}
.step3-location p:nth-child(2) {
  margin-top: 10px !important;
  margin-bottom: 20px !important;
}
.step3-location p:nth-child(2) span {
  font-size: 1rem;
  text-transform: initial;
  text-decoration: none !important;
}
.direccion-label h6:nth-child(1) {
  font-size: 1.1rem;
  font-family: 'Oswald', sans-serif;
  letter-spacing: 0.5px;
}
.react-calendar {
  width: 550px !important;
  border: none !important;
  line-height: 1.2rem !important;
}
.react-calendar__tile--now,
.react-calendar__tile--now:enabled:focus {
  background: transparent !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus,
.react-calendar__tile--active {
  background: #2196f3 !important;
  color: white !important;
  box-shadow: 4px 4px 10px 1px lightgray;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 2em !important;
}
.react-calendar__month-view__weekdays__weekday abbr[title] {
  text-decoration: none !important;
}
.calendar-container {
  display: flex;
  flex-direction: column;
}
.calendar {
  display: flex;
}
.calendar-date {
  width: 80%;
}
.calendar-hour-container {
  width: 20%;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.calendar-hour-free {
  text-align: center;
  margin: 1px 0px;
  padding: 4px;
  background-color: white;
  border: none;
  cursor: pointer;
}
.calendar-hour-selected,
.calendar-hour-selected:hover,
.calendar-hour-selected:focus,
.calendar-hour-selected:active {
  background: #2196f3;
  box-shadow: 4px 4px 10px 1px lightgrey;
  color: white;
  margin: 1px 0px;
  padding: 4px;
  transform: scale(1.1);
  border: none;
}
.calendar-hour-free:hover,
.calendar-hour-free:active,
.calendar-hour-free:focus {
  background: #2196f3;
  box-shadow: 4px 4px 10px 1px lightgrey;
  color: white;
  transform: scale(1.1);
}
.calendar-hour-busy {
  text-align: center;
  background-color: white;
  border: none;
  color: lightgray;
  text-align: center;
  margin: 1px 0px;
  padding: 4px;
  text-decoration: line-through;
}
.cal-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 10px;
}
.cal-header-container span {
  font-size: 1.8rem;
  text-transform: uppercase;
  font-family: 'Oswald';
}
.calendar-hour-title {
  display: flex;
  justify-content: center;
  align-items: center;
}
.calendar-hour-title span {
  font-size: 1.1rem;
  text-transform: uppercase;
  font-weight: bold;
}
.dashboard-tabs-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 20px;
}
.dashboard-tab,
.dashboard-tab-active {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 64px;
  width: 64px;
  border: none;
  cursor: pointer;
  background-color: transparent;
}
.dashboard-tab i {
  font-size: 2.2rem;
  color: lightgray;
}
.dashboard-tab span:nth-child(2) {
  font-size: 0.9rem;
  margin-top: 4px;
  font-family: 'Oswald';
  letter-spacing: 4px;
  color: lightgray;
}
.dashboard-tab-active {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none;
  transform: scale(1.1);
  text-shadow: 4px 4px 4px lightgrey;
}
.dashboard-tab-active i {
  font-size: 2.2rem;
  color: #2196f3;
  transition: all 1s linear;
  animation: shakeIcon 1s linear infinite;
}
.dashboard-tab-active span:nth-child(2) {
  margin-top: 4px;
  font-family: 'Oswald';
  letter-spacing: 4px;
  color: #2196f3;
}
.dashboard-tab-active span:nth-child(3) {
  width: 100px;
  height: 2px;
  margin-top: 10px;
  border-bottom: 1px solid #2196f3;
  animation: showUnderline 1s linear forwards;
}
.dashboard-tab:hover {
  transition: all 0.3s linear;
  transform: scale(1.1);
  color: #2196f3;
  background-color: white;
}
.dashboard-tab-active:active,
.dashboard-tab-active:focus,
.dashboard-tab-active:active *,
.dashboard-tab-active:focus * {
  color: #2196f3;
  background: white;
}
@keyframes showUnderline {
  0% {
    opacity: 0;
    width: 0px;
  }
  25% {
    opacity: 0.25;
    width: 25px;
  }
  50% {
    opacity: 0.5;
    width: 50px;
  }
  75% {
    opacity: 0.75;
    width: 75px;
  }
  100% {
    opacity: 1;
    width: 100px;
  }
}
@keyframes shakeIcon {
  0% {
    transform: rotateZ(0deg);
  }
  25% {
    transform: rotateZ(10deg);
  }
  50% {
    transform: rotateZ(0deg);
  }
  75% {
    transform: rotateZ(-10deg);
  }
  100% {
    transform: rotateZ(0deg);
  }
}
.enable-card-overflow-y {
  overflow-y: scroll !important;
}
.enable-card-overflow-x {
  overflow-x: scroll !important;
}
.enable-card-overflow {
  overflow: scroll !important;
}
.no-event-container {
  margin: 0px !important;
  padding: 0px !important;
}
.no-event-container img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.no-event-container a {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}
.reminder-consulta-container {
  height: 100%;
  width: 100%;
}
.reminder-consulta-container div {
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  height: 100px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
}
.reminder-consulta-container div p:nth-child(1) {
  font-weight: bold;
  font-size: 1.2rem;
  animation: breath 2s linear infinite;
}
.reminder-consulta-container div p:nth-child(2) span {
  font-weight: bold;
  font-size: 1.2rem;
  color: #2196f3;
}
.reminder-consulta-container div p:nth-child(3) span {
  font-weight: bold;
  font-size: 0.8rem;
  color: black;
}
.reminder-consulta-container div p:nth-child(3) {
  font-size: 0.8rem;
  color: grey;
  text-align: center;
}
@keyframes breath {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}
.trunk {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.fixed-width-table tr td:nth-child(1) {
  width: 100px !important;
}
.modal-consulta {
  display: flex;
  flex-direction: column;
}
.modal-consulta-info {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
}
.modal-consulta-info p:nth-child(1) {
  padding: 20px 0px;
  font-size: 1.1rem;
  font-weight: bold;
  text-align: center;
}
.modal-consulta-info p:nth-child(2) {
  padding: 0px 4px;
}
.modal-consulta-info-head {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 4px 4px 10px 1px lightgrey;
  z-index: 1;
  background: #2196f3;
}
.modal-consulta-info-head div {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  color: white;
  width: 100%;
}
.modal-consulta-info-head h5 {
  color: white;
}
@media only screen and (max-width: 600px) {
  .modal-consulta-info-head {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 4px 4px 10px 1px lightgrey;
    z-index: 1;
    background: #2196f3;
  }
  .modal-consulta-info-head div {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    color: white;
    width: 100%;
  }
}
.no-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 !important;
  padding: 0 !important;
  width: 100%;
  height: 100%;
}
.no-data img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.no-data div {
  background: white;
  position: absolute;
  bottom: 20px;
}
.tab-btn-cli {
  border: none;
  text-transform: uppercase;
}
.tab-btn-cli:hover {
  cursor: pointer;
}
.tab-btn-cli:focus,
.tab-btn-cli:active,
.tab-btn-cli-active:focus,
.tab-btn-cli-active:active {
  background-color: transparent;
}
.tab-btn-cli-active {
  border-bottom: 1px solid red;
  border-top: none;
  border-left: none;
  border-right: none;
  text-transform: uppercase;
  width: 100%;
}
.tab-btn-cli-active span {
  font-weight: bold;
}
.tab-cli {
  height: 300px;
  overflow-y: scroll;
}
.drag-n-drop-container {
  height: 200px;
  width: 100%;
  border: 3px dashed lightgrey;
  border-radius: 10px;
  margin: 20px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.drag-n-drop-container > p {
  color: rgb(180, 180, 180);
}
.drag-n-drop-active {
  height: 200px;
  width: 100%;
  border: 3px dashed #2196f3;
  border-radius: 10px;
  margin: 20px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.drag-n-drop-action {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
.tab-admin-tabs {
  position: fixed;
  top: 80px;
  left: 0;
  right: 0;
  height: 60px;
  background: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  z-index: 2;
}
.tab-btn-blog-admin,
.tab-btn-blog-admin:active,
.tab-btn-blog-admin:focus {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  border: none;
  background-color: transparent;
  width: 250px;
}
.tab-btn-blog-admin i {
  font-size: 2.2rem;
}
.tab-btn-blog-admin-edit {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  border: none;
  background-color: transparent;
  width: 250px;
  color: #cc0e44;
  transform: scale(1.1);
  transition: all 0.3s linear;
  font-weight: bold;
}
.tab-btn-blog-admin-edit i {
  font-size: 2.2rem;
}
.tab-btn-blog-admin-edit:active,
.tab-btn-blog-admin-edit:focus {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  border: none;
  background-color: transparent;
  width: 250px;
}
.tab-btn-blog-admin-active {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  border: none;
  background-color: transparent;
  width: 250px;
  color: #2196f3;
  transform: scale(1.1);
  transition: all 0.3s linear;
  font-weight: bold;
}
.tab-btn-blog-admin-active i {
  font-size: 2.2rem;
}
.tab-btn-blog-admin-active:active,
.tab-btn-blog-admin-active:focus {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  border: none;
  background-color: transparent;
  width: 250px;
}
.editor-container {
  margin: 40px 40px;
  box-shadow: 4px 4px 10px 1px lightgrey;
  height: 80vh;
  overflow: scroll;
}
.custom-size-editor {
  height: 70vh !important;
  overflow: scroll !important;
}
.tab-admin-all {
  margin: 20px 20px;
}
.all-posts-wrapper {
  margin-top: 80px;
  height: 100%;
  overflow: scroll;
}
.tab-admin-new {
  margin-top: 60px;
}
.input-inside-btn {
  position: absolute;
  right: 0;
  top: 5px;
}
.chip-container {
  display: flex;
  flex-wrap: wrap;
}
.custom-chip,
.custom-chip:active,
.custom-chip:focus {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid #2196f3;
  border-radius: 5px;
  background: transparent;
  padding: 5px;
  margin: 1px;
}
.custom-chip span {
  text-transform: lowercase;
  color: #2196f3;
}
.custom-chip i {
  color: #2196f3;
  font-size: 1.2rem;
  padding-left: 2px;
}
.post-img-container {
  height: 600px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.post-img-container img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.results-container {
  position: absolute;
  left: 10px;
  right: 10px;
  background-color: white;
  height: 150px;
  max-height: 200px;
  z-index: 1;
  box-shadow: 0px 6px 20px -1px grey;
  display: flex;
  flex-direction: column;
  overflow: scroll;
}
.results-container > button {
  background-color: white;
  border: none;
  width: 100%;
  height: 40px;
  text-align: left;
}
.results-container > button:hover {
  background-color: #2196f3;
  color: white;
}
.subtitle-nueva-consulta span:nth-child(1),
span:nth-child(3) {
  font-weight: bold;
}
.subtitle-nueva-consulta span:nth-child(2),
span:nth-child(4) {
  color: #2196f3;
}
.subtitle-details {
  display: flex;
  justify-content: center;
  align-items: center;
}
.subtitle-details div:nth-child(1) {
  position: relative;
}
.dropdown-date {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 1;
  width: 110px;
  max-height: 150px;
  overflow: scroll;
  background-color: white;
  box-shadow: 2px 2px 10px 0px lightgray;
}
.dropdown-date > button {
  padding: 0 !important;
  color: #2196f3;
}
.dropdown-date > button:hover {
  color: white;
  background-color: #2196f3;
}
.client-cases-right {
  height: 500px;
  border-left: 1px solid lightgray;
  overflow: scroll;
}
.no-docs {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.client-cases-right ul {
  display: flex;
  flex-direction: column;
}
.client-cases-right ul > li {
  height: 50px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.client-cases-right ul > li:hover {
  box-shadow: 1px 1px 10px 1px lightgrey;
}
